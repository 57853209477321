import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { Box, Text, Card, Heading } from 'theme-ui'
import { Layout, Stack, Main, Hero } from '@layout'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import { getStaticPageSeoDetails } from '@utils-blog'
import HeroComponent from '../components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css } from 'theme-ui'
/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  innerSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  boxs: {
    display: `grid`,
    gridTemplateColumns: 'auto auto',
    gap: '20px',
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'auto'
    },
    '>div >div': {
      height:`100%`
    }
  },
  buttons: {
    m: 1
  },
  wrapper: {
    textAlign: `center`,
    display: 'flex',
    alignItems: 'start'
  },
  avatarWrapper: {
    mb: 4,
    mr: 3,
    borderRadius: 20,
    overflow: 'hidden'
  },
  section: {
    textAlign: 'start',
    maxWidth: '300px'
  },
  title: {
    color: `omegaDark`
  },
  des: {
    textAlign: 'left',
    lineHeight: '1.5'
  },
  pBox: {
    p: 50
  }
}

export default props => {
  let seoData = getStaticPageSeoDetails('shared.board-members')
  const boardMembersSeoData = {
    ...seoData,
    BoardMembers: {
      ...seoData?.BoardMembers,
      keywords: seoData?.BoardMembers?.keywords?.split('\n')
    }
  }
 
  const { allStrapiBoardMember } = useStaticQuery(boardMemberQuery)
  let allBoardMember = allStrapiBoardMember.nodes
  allBoardMember.forEach(element => {
    element.avatarImage = element.image[0]?.ImageSharp_vertical.gatsbyImageData
  })

  // Sorting board members by the last word or the last word before a comma of their name. ( In short sorting by surname )
  allBoardMember = allBoardMember.sort((a, b) => {
    const getLastWord = (str) => {
      const words = str.split(/\s+/);
      return words[words.length - 1];
    };

    const getLastWordBeforeComma = (str) => {
      const wordsBeforeComma = str.split(',')[0].trim().split(/\s+/);
      return wordsBeforeComma[wordsBeforeComma.length - 1];
    };

    const aLastWord = a.name.includes(',') ? getLastWordBeforeComma(a.name) : getLastWord(a.name);
    const bLastWord = b.name.includes(',') ? getLastWordBeforeComma(b.name) : getLastWord(b.name);

    return aLastWord.localeCompare(bLastWord);
  });


  return (
    <Layout {...props}>
      <Seo
        title={boardMembersSeoData?.BoardMembers?.metaTitle}
        description={boardMembersSeoData?.BoardMembers?.metaDescription}
        keywords={boardMembersSeoData?.BoardMembers?.keywords}
        thumbnail={boardMembersSeoData?.BoardMembers?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'boardMembers'}
            {...props}
            callFrom={'BoardMembers'}
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <div id='board-members'>
            <Section title='Meet Our Board'>
              <Text variant='p'>* Indicates Executive Committee Member</Text>
              <Box sx={styles.boxs}>
                {allBoardMember.map((member, i) => {
                  return (
                    <Box>
                      <Card variant={['paper']} key={i}>
                        <Box sx={styles.wrapper}>
                          <Box sx={styles.avatarWrapper}>
                            <Img
                              css={css({
                                height: `full`,
                                verticalAlign: `middle`, //avoid baseline gap
                                img: {
                                  bg: `omegaLighter`
                                }
                              })}
                              image={member.avatarImage}
                              alt={member.title}
                            />
                          </Box>
                          <Box sx={styles.section}>
                            <Heading variant='h3'>{member.name}</Heading>
                            <Text variant='p' sx={styles.innerSection}>
                              {member.title}{' '}
                              <GLink
                                style={{
                                  textDecoration: 'none',
                                  color: '#667eea'
                                }}
                                target='_blank'
                                to={member.Link}
                              >
                                {' '}
                                {member.institution}
                              </GLink>
                            </Text>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  )
                })}
              </Box>
            </Section>
          </div>
        </Main>
      </Stack>
    </Layout>
  )
}

const boardMemberQuery = graphql`
  query {
    allStrapiBoardMember(sort: {order: ASC, fields: name}) {
      nodes {
        name
        title
        Link
        institution
        image {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(width: 130)
          }
        }
      }
    }
  }
`
